import './App.css';
import {TextField, Button} from '@mui/material';
import {useEffect, useState, useRef, useLayoutEffect} from "react"
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


var fontSize = 0;

const FontEqualizer = () => {
  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    const updateFontSize = () => {
      const widthProportion = window.innerWidth * 0.0085
      const height = window.innerHeight  * 0.0085
      if (height > widthProportion){
        setScreenWidth(widthProportion*2);
      }else{
        setScreenWidth(widthProportion);
      }
    };

    updateFontSize();
    window.addEventListener('resize', updateFontSize);

    return () => window.removeEventListener('resize', updateFontSize);
  }, []);

  if(!fontSize || window.innerWidth > window.innerHeight){
    return screenWidth
  }else {
    return fontSize
  }

}

function ScrollToTop() {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

function App() {
    fontSize = FontEqualizer()
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop/>
        <Nav></Nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AboutMe" element={<AboutMe/>} />
          <Route path="/Services" element={<Services/>} />
          <Route path="/ContactMe" element={<ContactMe/>} />
        </Routes>
        <Footer/>
        {/* <div className='homeBackground'>
          <Home></Home>
        </div>
        <HashLinkboutMe></AboutMe>
        <div className='transition'>

        </div>
        <div className='bluebackground'>
        <Services></Services>
        <Testimonials></Testimonials>
        </div>
        <ContactMe></ContactMe> */}
      </BrowserRouter>
    </div>
  );
}

const ContactMe = () => {
  const [phoneNum, setPhoneNum] = useState("")

  const setPhoneNumber = (e) => {
    let formattedNumber;
    const { name, value } = e.target;
    const { length } = value;
    // Filter non numbers
    const regex = () => value.replace(/[^0-9\.]+/g, "");
    // Set area code with parenthesis around it
    const areaCode = () => `(${regex().slice(0, 3)})`;

    // Set formatting for first six digits
    const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;

    // Dynamic trail as user types
    const trailer = (start) => `${regex().slice(start,
    regex().length)}`;
      if (length < 3) {
        // First 3 digits
        formattedNumber = regex();
      } else if (length === 4) {
        // After area code
        formattedNumber = `${areaCode()} ${trailer(3)}`;
      } else if (length === 5) {
        // When deleting digits inside parenthesis
        formattedNumber = `${areaCode().replace(")", "")}`;
      } else if (length > 5 && length < 9) {
        // Before dash
        formattedNumber = `${areaCode()} ${trailer(3)}`;
      } else if (length >= 10) {
        // After dash
        formattedNumber = `${firstSix()}-${trailer(6)}`;
      }
    const formattedObject = {
      target: { name: name, value: formattedNumber }
    };
    setPhoneNum(formattedNumber)
  }

  return (
    <div id="contactme" className='contactme'>
      {window.innerWidth > window.innerHeight && <img className="desktopimg" src={require("./assets/unnamed-removebg2.png")}></img>}
      <div className='formContainer'>
        <h1 style={{ fontSize: `${fontSize*6}px` }}>Send me a message</h1>
        <form name="contact" method="POST" >
          <input type="hidden" name="form-name" value="contact"/>
          <TextField  inputProps={{style: {fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1)}px`, backgroundColor: 'white'}}} InputLabelProps={{style: {fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1)}px`}}}
          className='formItem short' required label="Name" name="name" margin="dense"/>
          <TextField  inputProps={{style: {fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1)}px`, backgroundColor: 'white'}}} InputLabelProps={{style: {fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1)}px`}}}
          className='formItem short' required label="Email" name="email" type="email" margin="dense"/>
          <TextField  inputProps={{style: {fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1)}px`, backgroundColor: 'white'}}} InputLabelProps={{style: {fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1)}px`}}}
          className='formItem short' required label="Phone" name="phone" type="tel" margin="dense" onChange={(v)=>setPhoneNumber(v)} value={phoneNum} />
          <TextField   inputProps={{style: {fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1)}px`, backgroundColor: 'white'}}} InputLabelProps={{style: {fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1)}px`}}}
          className='formItem' required label="How did you hear about us?" name="reference"  margin="dense"/>
           <TextField rows={3} multiline sx={{'& .MuiInputBase-root': {backgroundColor: 'white'}}} inputProps={{style: {fontSize: `${fontSize*1}px`}}} InputLabelProps={{style: {fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1)}px`}}}
          className='formItem' required label="Your Message (birth location, due date, etc.)" name="message"  margin="dense"/>
          <Button className='formItem button' type="submit" variant="contained"
          sx={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1)}px`,color: '#666',backgroundColor: '#FFD6D4', marginTop: '3%', marginBottom: '1%'   }} >
            Send
          </Button>
        </form>
        <div className='contacticons'>
        <HashLink target="_blank" to="tel:540-878-8988"><img src={require('./assets/phoneblue.png')}/></HashLink>
        <HashLink target="_blank" to="mailto:chelsie.pasley@gmail.com"> <img src={require('./assets/emailblue.png')}/></HashLink>
        <HashLink to="https://www.instagram.com/bonded.births/" target="_blank"><img src={require('./assets/instagramblue.png')}/></HashLink>
        <HashLink to="https://www.facebook.com/people/Bonded-Births-Doula-Services/61550988598253/" target="_blank"><img src={require('./assets/facebookblue.png')}/></HashLink>
        </div>
      </div>
      {window.innerHeight > window.innerWidth && <img className="mobileimg" src={require("./assets/unnamed-removebg2.png")}></img>}
    </div>
  )
}

const Testimonials = () => {
  const [current, setCurrent] = useState(0)
  const [switcher, setSwitcher] = useState(false)
  const [delay, setDelay] = useState(5000)
  const [cls, setCls] = useState("move")
  const [lastDirection, setLastDirection] = useState("move")

  const testimonials = [
    {quote: "When we first started researching natural births, a lot of information focused on the role of the \"doula or partner\", so when we spoke to Chelsie, one of my concerns was what my role would be. Chelsie assured me that she would be there to support us and not replace me in the delivery room. Chelsie was great in the delivery room, and I was able to also be there for my wife, reading positive affirmations, holding her hand, and then cutting the umbilical cord when our daughter was born!", customer: "Alan. T"},
    {quote: "She made me feel like I wasn't alone and that my feelings were valid and they mattered but to also not stress too much. Chelsie reassured me that I can do whatever I feel comfortable with and to follow my motherly instincts…Chelsie is there and is still supportive as ever, i cannot think her enough for everything she did for me and my baby's father…she had given him a peace of mind also. 10/10 recommend Chelsie as your doula to help ease your mind for birth, and to have a friend and someone who listens to you.", customer: "Angel B."},
    {quote: "Chelsie helped me get my dream birth! She was assertive with the nursing staff and she knew how to calm me down using techniques like touch or mantras. In a follow-up call post-delivering the baby, Chelsie helped with more than the baby. I had asked questions about staying close to my husband when our baby is so demanding and Chelsie helped with those marital questions relevant to having a baby too. Chelsie has a big heart and genuinely wants to help families.", customer: "Daniella B."},
    {quote: "When my \"home\" birth started to go a different way than I had planned and wanted she came to the hospital and made sure I was very informed in the options I had and helped me make a more confident decision... I highly recommend Chelsie for anyone who wants to take full control of the choices in their pregnancy or especially anyone who doesn't want to go through pregnancy alone. She will make sure you feel like you have all the support you need!", customer: "Destiny R."},
    {quote: "Hiring Chelsie as our doula was the BEST thing that could have happened for us! She was so supportive throughout the entire pregnancy, really helping to ease my mind and concerns as a first time mom...When it was go time for delivery she kept me calm and laughing, I felt safe, and she coached me through labor which I believe really helped me progress as well as I did! The support I felt during pregnancy, labor, and postpartum is unmatched.", customer: "Natelie V."},
    {quote: "I'm genuinely grateful to have Chelsie as my Doula for the birth of my twins. She supported my family and me in a critical time of need by providing encouragement, compassion, and confidence that put us at ease. The twins were birthed by c-section, and shortly following their birth, I hemorrhaged. Chelsie was our strength during this scary time. She gave me encouraging affirmations and comforted me by holding my baby girls close to me. I can't express the amount of gratitude I have for her support during this time.", customer: "Christy E."},
    {quote: "Even if you have a supportive spouse / partner for this journey, I would still suggest you hire Chelsie as your Doula! Her knowledge of the pregnancy journey, from start to finish, in every stage, is comforting and far more trustworthy than googling information or concerns! Her sense of calm radiates through the room, no matter what is going on!", customer: "Lindsey E."},
    {quote: "If you're reading this, it's your sign to hire Chelsie as your doula. I couldn't have done my natural hospital birth without her. Through breathing techniques she shared with me, to emotional support, to counter pressure and spinning babies during my labor, she helped me to achieve the exact birth I desired. She supported all of my wishes and helped to advocate for me in the hospital. Having a doula is the way to go and 100% you should choose Chelsie to walk alongside you.", customer: "Jordan C."},
    {quote: "Chelsie made me feel more confident this go around and she provided tips to help that I didn't know for my first birth! She is also just so sweet, it feels like I'm talking to a girlfriend while also feeling confident about her birthing knowledge! If you can't get on her books for doula services you should absolutely at least do a birth planning class with her!", customer: "Katie N."},
    {quote: "Through all the stages of pregnancy and labor she was a huge help with easing my mind and talking me through things, understanding the process deeper, and knowing my rights... I can't thank her enough for all the love, joy, emotional journey and tears she's been with me through. If you want and doula AND someone you can call a friend- look no further.", customer: "Angela S."},
    {quote: "Chelsie’s knowledge, expertise and passion for this profession is 10/10. She's extremely supportive but not overbearing and willing to listen as well as advocate for you while you experience the greatest gift of life!", customer: "Amanda K."},
    {quote: "Chelsie walked us through everything and explained the entire process she helped and coached my wife through contractions and labor and showed me ways I can support and be there for my wife as she was laboring! Best experience ever it was a true blessing having Chelsie on our side the entire way!", customer: "Zack S."},
    {quote: "Chelsie made me some amazing cookies that help with milk production and MY did they work…The only thing different I did this time around is eat the cookies and I have been able to exclusively breastfeed and provide my sweet baby with the nourishment he needs which has made postpartum less stressful for me and my baby.", customer: "Shelley P."},
  ]

  useEffect(() => {
    let intervalId;
      intervalId = setInterval(() => {
        if(cls == "movedelay"){
          incrementCurrent(true)
          setDelay(60000)
        }else if(cls == "movebackdelay"){
          incrementCurrent(false)
          setDelay(60000)
        }else {
          incrementCurrent(true)
          setDelay(5000)
        }
      }, delay);

    return () => clearInterval(intervalId);
  });

  const incrementCurrent = (forward) => {
    setSwitcher(!switcher)
    if (window.innerHeight > window.innerWidth){
      // mobile
      if(forward){
        if(lastDirection != "moveback"){
          if(current == testimonials.length - 1){
            setCurrent(0)
          }else{
            setCurrent(current+1)
          }
        }else{
          if(current == 0){
            setCurrent(testimonials.length - 1)
          }else{
            setCurrent(current - 1)
          }
        }
        setCls("move")
        setLastDirection("move")
      }else{
        if(lastDirection != "move"){
          if(current == 0){
            setCurrent(testimonials.length - 1)
          }else{
            setCurrent(current - 1)
          }
        }else{
          if(current == testimonials.length - 1){
            setCurrent(0)
          }else{
            setCurrent(current+1)
          }
        }
        setCls("moveback")
        setLastDirection("moveback")
      }
    }else{
      // desktop
      if(forward){
        if(lastDirection != "moveback"){
          if(current + 3 >= testimonials.length){
            setCurrent(current+3 - (testimonials.length))
          }else{
            setCurrent(current+3)
          }
        }else{
          if(current - 3 < 0){
            setCurrent((testimonials.length + (current - 3)))
          }else{
            setCurrent(current - 3)
          }
        }
        setCls("move")
        setLastDirection("move")
      }else{
        if(lastDirection != "move"){
          if(current - 3 < 0){
            setCurrent((testimonials.length + (current - 3)))
          }else{
            setCurrent(current - 3)
          }
        }else{
          if(current + 3 > testimonials.length - 1){
            setCurrent(current+3 - (testimonials.length))
          }else{
            setCurrent(current+3)
          }
        }
        setCls("moveback")
        setLastDirection("moveback")
      }
    }
  }


  const startX = useRef(0);

  const handleMouseDown = (e) => {
    startX.current = e.clientX;
  };

  const handleMouseUp = (e) => {
    const threshold = 50; // Adjust as needed
    const deltaX = e.clientX - startX.current;
    if (deltaX > threshold) {
      //swipe right
      setDelay(0)
      setCls("movebackdelay")
    } else if (deltaX < -threshold) {
      //swipe left
      setDelay(0)
      setCls("movedelay")
    }
  };


  const [touchX, setTouchX] = useState(0);

  const handleTouchStart = (e) => {
    console.log("start")
    setTouchX(e.touches[0].clientX);
    console.log(e.touches[0].clientX)
  };

  const handleTouchEnd = (e) => {
    const threshold = 50; // Adjust as needed
    const endX = e.changedTouches[0].clientX;
    const deltaX = endX - touchX;
    console.log(endX - touchX)

    if (deltaX > threshold) {
      //swipe right
      setDelay(0)
      setCls("movebackdelay")
    } else if (deltaX < -threshold) {
      //swipe left
      setDelay(0)
      setCls("movedelay")
    }
  };


  return (
    <div className="testimonialsContainer" id="testimonials"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
 >
    {/* <div className="testimonialsContainer" id="testimonials"> */}
    <img src={require('./assets/5stars.png')}></img>
        <div className='testimonials'>
          {/* mobile */}
          {window.innerHeight > window.innerWidth && <>
            <div className={`testimonial ${(switcher)? cls + '1' :cls + '2'}`}>

            <div style={{ fontSize: `${fontSize*4.5}px` }} className='quotation'>“</div>
            <div style={{ fontSize: `${fontSize*2.25}px` }} className='quote'>
            {testimonials[(current == 0 )? testimonials.length - 1 : current-1].quote}
            </div>
            <div style={{ fontSize: `${fontSize*4.5}px` }} className='quotation second'>”</div>
            <div style={{ fontSize: `${fontSize*3}px` }}  className='customer'>
              - {testimonials[(current == 0 )? testimonials.length - 1 : current-1].customer}
            </div>
          </div>
          <div className={`testimonial ${(switcher)? cls + '1' :cls + '2'}`}>

            <div style={{ fontSize: `${fontSize*4.5}px` }} className='quotation'>“</div>
            <div style={{ fontSize: `${fontSize*2.25}px` }} className='quote'>
              {testimonials[current].quote}
            </div>
            <div style={{ fontSize: `${fontSize*4.5}px` }} className='quotation second'>”</div>
            <div style={{ fontSize: `${fontSize*3}px` }} className='customer'>
              - {testimonials[current].customer}
            </div>
          </div>
          <div className={`testimonial ${(switcher)? cls + '1' :cls + '2'}`}>
            <div style={{ fontSize: `${fontSize*4.5}px` }} className='quotation'>“</div>
            <div style={{ fontSize: `${fontSize*2.25}px` }} className='quote'>
              {testimonials[(current == testimonials.length - 1 )? 0 : current+1].quote}
            </div>
            <div style={{ fontSize: `${fontSize*4.5}px` }} className='quotation second'>”</div>
            <div style={{ fontSize: `${fontSize*3}px` }} className='customer'>
              - {testimonials[(current == testimonials.length - 1 )? 0 : current+1].customer}
            </div>
          </div>
          </>
          }
          {/* desktop */}
          {window.innerWidth > window.innerHeight  && <>
          <div className={`testimonial ${(switcher)? cls + '1' :cls + '2'}`}>
                        <div className='test'>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation'>“</div>
                          <div style={{ fontSize: `${fontSize*1.2}px` }} className='quote'>
                            {testimonials[(current - 3 <= 0 )? testimonials.length + (current - 4) : current-4].quote}
                          </div>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation second'>”</div>
                          <div style={{ fontSize: `${fontSize*1.6}px` }}  className='customer'>
                            - {testimonials[(current - 3 <= 0 )? testimonials.length + (current - 4) : current-4].customer}
                          </div>
                        </div>
                        <div className='test'>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation'>“</div>
                          <div style={{ fontSize: `${fontSize*1.2}px` }} className='quote'>
                            {testimonials[(current - 2 <=  0 )? testimonials.length + (current - 3) : current - 3].quote}
                          </div>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation second'>”</div>
                          <div style={{ fontSize: `${fontSize*1.6}px` }}  className='customer'>
                            - {testimonials[(current - 2 <= 0 )? testimonials.length + (current - 3) : current-3].customer}
                          </div>
                        </div>
                        <div className='test'>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation'>“</div>
                          <div style={{ fontSize: `${fontSize*1.2}px` }} className='quote'>
                            {testimonials[(current - 1 <= 0 )? testimonials.length + (current - 2) : current-2].quote}
                          </div>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation second'>”</div>
                          <div style={{ fontSize: `${fontSize*1.6}px` }}  className='customer'>
                            - {testimonials[(current - 1 <= 0 ) ? testimonials.length + (current - 2) : current-2].customer}
                          </div>
                        </div>
                      </div>
          <div className={`testimonial ${(switcher)? cls + '1' :cls + '2'}`}>
                      <div className='test'>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation'>“</div>
                          <div style={{ fontSize: `${fontSize*1.2}px` }} className='quote'>
                            {testimonials[(current == 0 )? testimonials.length - 1 : current-1].quote}
                          </div>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation second'>”</div>
                          <div style={{ fontSize: `${fontSize*1.6}px` }}  className='customer'>
                            - {testimonials[(current == 0 )? testimonials.length - 1 : current-1].customer}
                          </div>
                        </div>
                        <div className='test'>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation'>“</div>
                          <div style={{ fontSize: `${fontSize*1.2}px` }} className='quote'>
                            {testimonials[current].quote}
                          </div>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation second'>”</div>
                          <div style={{ fontSize: `${fontSize*1.6}px` }} className='customer'>
                           - {testimonials[current].customer}
                          </div>
                          </div>
                          <div className='test'>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation'>“</div>
                          <div style={{ fontSize: `${fontSize*1.2}px` }} className='quote'>
                            {testimonials[(current == testimonials.length - 1 )? 0 : current+1].quote}
                          </div>
                          <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation second'>”</div>
                          <div style={{ fontSize: `${fontSize*1.6}px` }} className='customer'>
                           - {testimonials[(current == testimonials.length - 1 )? 0 : current+1].customer}
                          </div>
                        </div>
                      </div>
          <div className={`testimonial ${(switcher)? cls + '1' :cls + '2'}`}>
                      <div className='test'>
                        <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation'>“</div>
                        <div style={{ fontSize: `${fontSize*1.2}px` }} className='quote'>
                          {testimonials[(current < testimonials.length - 2 )? current + 2 : current + 2 - testimonials.length].quote}
                        </div>
                        <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation second'>”</div>
                        <div style={{ fontSize: `${fontSize*1.6}px` }} className='customer'>
                         - {testimonials[(current < testimonials.length - 2 )? current + 2  : current + 2 - testimonials.length].customer}
                        </div>
                      </div>
                      <div className='test'>
                        <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation'>“</div>
                        <div style={{ fontSize: `${fontSize*1.2}px` }} className='quote'>
                          {testimonials[(current < testimonials.length - 3 )?  current + 3 : current+3- testimonials.length].quote}
                        </div>
                        <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation second'>”</div>
                        <div style={{ fontSize: `${fontSize*1.6}px` }} className='customer'>
                         - {testimonials[(current < testimonials.length - 3 )? current + 3 : current+3- testimonials.length].customer}
                        </div>
                      </div>
                      <div className='test'>
                        <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation'>“</div>
                        <div style={{ fontSize: `${fontSize*1.2}px` }} className='quote'>
                          {testimonials[(current < testimonials.length - 4 )?  current + 4 : current+4- testimonials.length].quote}
                        </div>
                        <div style={{ fontSize: `${fontSize*2.5}px` }} className='quotation second'>”</div>
                        <div style={{ fontSize: `${fontSize*1.6}px` }} className='customer'>
                         - {testimonials[(current < testimonials.length - 4 )?  current + 4 : current + 4- testimonials.length].customer}
                        </div>
                      </div>
          </div>
          </>}
      </div>

    </div>
  )
}


const AboutMe = () => {

  return (
        <div id="AboutMe" className='aboutme'>
          <h1 style={{ fontSize: `${fontSize*9.75}px` }}>Who I Am</h1>
          {/* <img className='topleft' src={require('./assets/banner8.png')}></img> */}
          {/* <img className='topright' src={require('./assets/banner9.png')}></img> */}
          {/* <img className='topright' src={require('./assets/')}></img> */}
          {/* <div className='credentials'>
            <img src={require('./assets/accreditation.png')}></img>
            <img src={require('./assets/longwood.png')}></img>
            <img src={require('./assets/childbirthinternational.png')}></img>
          </div> */}
          <div className='aboutmeContainer'>
            <div className="philosophy right">

            <img src={require("./assets/clipart1.png")}></img>
              <h1 style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?4: 2.5)}px` }}>My Philosophy</h1>
              <div style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8 : 1.1)}px` }} className='philosophyContainer'>

              At Bonded Births LLC, I believe that every family's unique wishes and preferences are the foundation of a positive and empowering birth experience. As a certified labor doula, I'm dedicated to providing compassionate, unbiased, and evidence-based care that honors your individuality.

              My goal is to support you in feeling confident, empowered, and loved throughout your pregnancy, childbirth, and postpartum journey. I understand that welcoming a new baby is a life-changing experience, and as a mother myself, I know firsthand the impact that compassionate support can have.

              As a doula, I proudly support all births, including VBACs, cesarean sections, home births, hospital births, medicated, and unmedicated births. Every birth is unique, and I'm committed to providing personalized support that honors your preferences and values.

              With Bonded Births, you'll find a safe, nurturing space to explore your hopes, fears, and dreams for your birth experience. I'm here to walk alongside you, offering guidance, reassurance, and unwavering support every step of the way.

                {/* <div>
                Welcome to Bonded Births Doula Services, where the wishes of your family are not only respected, but appreciated. My name is Chelsie Pasley. I am a certified labor doula committed to providing nurturing, unbiased evidence-based care to growing families through pregnancy, childbirth, and the early postpartum period.
                </div>
                <div>
                With Bonded Births, you will feel empowered and confident in your ability to give birth your way, while feeling loved and supported through the entire journey! Your family is your priority and welcoming a new baby in to the world is life changing. As a mother myself, I understand that the right support can truly make all the difference.
                </div> */}
              </div>
            </div>

          <img className="mobile" src={require("./assets/clipart1.png")}></img>
            <div className='doula'>
            <img src={require("./assets/clipart3.png")}></img>
              <h1 style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?4: 2.5)}px` }}>Why I Became A Doula</h1>
              <div style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1.1)}px` }} className='doulaContainer'>

              As a happily married mother of two, I've experienced firsthand the transformative power of childbirth. My background as a social worker, advocating for children and families, has taught me the importance of empathy, understanding, and empowerment. These values guide my work as a birth doula, providing judgment-free support to women and families.

              Helping others is my passion, and I'm dedicated to making a positive impact. After my own births, I realized the importance of emotional and psychological support during the postpartum period. Unfortunately, I didn't receive the support I needed, which sparked my desire to help others during this time of transformation and

              healing. Spreading awareness about postpartum anxiety and depression is crucial. We often focus on the newborn, forgetting to check in with the new mom. Motherhood is a life-changing experience, and it's not always easy. As a doula, my goal is to support those struggling with anxiety and depression, making sure they feel seen, heard, and loved.

              Being part of the sacred journey of childbirth is an honor, and witnessing the transformation of new mothers is a privilege. I believe it takes a village to support new families, and I'm committed to providing love, support, and hope to those I serve.

                {/* <div>
                  I am happily married with two crazy, silly daughters. Before becoming a doula, I was a social worker working with children and families in and out of the child welfare system. My background in social work, advocacy, and empowerment helps me to provide a judgement free and understanding outlook to the women and families I support now. Helping others is my love language and I am truly passionate about it.
                </div>
                <div>
                  I knew I wanted to be the emotional and psychological support I never received postpartum after my births. Spreading awareness of postpartum anxiety and depression is so important. We are so quick to want to check in on a new born baby, but we too often forget to check in with the new mom and woman that was born the moment her baby entered the world. Motherhood is transformative.
                </div>
                <div>
                  And sometimes it’s not pretty! My goal as a doula is to help those who are struggling with anxiety and depression, to help them feel seen and heard in their feelings. To provide them with love and support and hope because it does take a village. Being a part of the sacred journey that is child birth is an honor. And witnessing the transformative process of becoming a brand new mom is such a privilege!
                </div> */}
              </div>
            </div>

            <img className="mobile" src={require("./assets/clipart3.png")}></img>
            <div className='story right'>

            <img src={require("./assets/clipart2.png")}></img>
              <h1 style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?4: 2.5)}px` }}>My Birth Stories</h1>
              <div style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1.1)}px` }} className='storyContainer'>

              My two births were a rollercoaster of emotions - both were induced due to high blood pressure, taking me on a journey I hadn't planned for. But despite the unexpected twists, I feel deeply grateful for my incredible birth teams who supported me every step of the way. Their compassion, expertise, and calming presence empowered me to have not one, but two successful unmedicated births.

              The aftermath of my first birth was a different story. The anxiety that crept in was suffocating, and I now realize it was deeply rooted in the loss of control I felt during my induction. The medical professionals who had been so supportive during my birth seemed to vanish after I gave birth, leaving me feeling isolated, alone, and struggling to

              cope. But my second birth was a game-changer. Being able to schedule my induction in advance gave me back the control I so desperately craved. The experience was nothing short of emotionally healing, and it sparked a fire within me. I realized that I wanted to be that support system for other women, to help them feel empowered, heard, and seen during their own birth journeys. To feel in control of their own stories.

              Throughout both of my experiences, I was blessed to have the unwavering support of my amazing husband. His love, encouragement, and strength helped carry me through the toughest and darkest moments of labor, birth, and postpartum. I am forever grateful for his presence by my side.

                  {/* <div>
                  In both of my births, I was induced due to high blood pressure; a choice (or lack of a choice) I was faced with during my pregnancy. The support my nurses provided me through labor was amazing and I truly believe their comfort and presence helped me to have successful, natural births as I had wanted. Though induction was not a part of
                  </div>
                  <div>
                  my birth preferences, I feel very fortunate to have had two healthy births! After my first birth, I struggled with post partum anxiety that I now recognize as being partially attributed to the lack of control I had over my own birth. The choice had been stripped from me and I felt thrusted in to a birth I hadn’t mentally prepared for yet.
                  </div>
                  <div>
                  The support I received postpartum from medical professionals was very minimal, leaving me feeling alone in my new motherhood journey. My second birth allowed me to schedule my induction when my blood pressure began to climb and was very emotionally healing for me and made me realize I wanted to give support to others in their births.
                  </div> */}
              </div>
            </div>

            <img className="mobile" src={require("./assets/clipart2.png")}></img>
        </div>

    </div>
  )
}

const Services = () => {

  return (
    <div id="services" className='services'>

<h1 className='servicesh1' style={{ fontSize: `${fontSize*9.75}px` }}>What I Do</h1>
<div className='servicesContainer'>
        <div className='item'>
          {/* <img src={require("./assets/prenatal.png")}/> */}
            <h2 style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?3.5: 2.5)}px` }}>1. Prenatal & Birth Planning</h2>
            <ul style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1.1)}px` }}>
               <li>One-on-one childbirth education providing you with evidence-based, unbiased information so you and your family are best equipped to make well-informed decisions.
               </li><li>We will address your fears, hesitations, or questions you may have regarding your birth.
               </li><li>Create a comprehensive birth plan that represents your desires during labor, birth, and immediate postpartum.
               </li><li>Explore coping strategies for pain and fatigue.
               </li><li>Unlimited communication throughout pregnancy and postpartum.
               </li><li>We will review everything and anything you wish to discuss pertaining to your birth!</li>
            </ul>
        </div>
        <div className='item'>
          {/* <img src={require("./assets/birth.png")}/> */}
            <h2 style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?3.5: 2.5)}px` }}>2. Labor / Birth Support</h2>
            <ul style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1.1)}px` }}>
            <li>On-call 24/7 starting at 37 weeks gestation - I will be ready to support you whenever baby decides to make their arrival!
            </li><li>Advocacy - I will protect your birth wishes and support your voice in the birthing room.
            </li><li>Physical comfort and emotional support throughout your labor and birth, whatever that may look like for you and your family's needs.
            </li><li>Provide suggestions for relaxation techniques, pain management strategies, and position changes for labor progression.
            </li><li>Breastfeeding and postpartum support for up to two hours immediately after birth.</li>
            </ul>
        </div>
        <div className='item'>
        {/* <img src={require("./assets/postpartum.png")}/> */}
            <h2 style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?3.5: 2.5)}px` }}>3. Postpartum & Breastfeeding</h2>
            <ul style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.8: 1.1)}px` }}>
            <li>Arrange for a visit within the first few weeks after giving birth to see how you and your family are adjusting.
            </li><li>I will provide you with helpful resources such as breastfeeding support and postpartum anxiety and depression information; this will include any local available resources.
            </li><li>Newborn care and self care education reviewed.
            </li><li> Unlimited postpartum support for entire first year postpartum (text, call, and resources).
            </li><li>Homemade lactation cookies gifted at first postpartum visit. Made to order as needed thereafter. Can be frozen for up to 6 months.</li>
            </ul>
        </div>
    </div>
    <p style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?2.5: 1.6)}px` }}>All Services are provided in my <span>Bundle of Joy</span> package or available individually.</p>
    </div>
  )
}

const Footer = () => {

  return (
    <div className='footerContainer'>
    <div className='footer'>
        <div className='footercol'>
          <h1 style={{ fontSize: `${fontSize*3.5}px` }}>Navigation</h1>
          <p style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.5: 1.3)}px` }}><HashLink to="/#home">Home</HashLink></p>
          <p style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.5: 1.3)}px` }}><HashLink to="/#testimonials">Testimonials</HashLink></p>
          <p style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.5: 1.3)}px` }}><HashLink to="/aboutme">About Me</HashLink></p>
          <p style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.5: 1.3)}px` }}><HashLink to="/services">Services</HashLink></p>
          <p style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.5: 1.3)}px` }}><HashLink to="/contactme">Contact Me</HashLink></p>
        </div>
        <div className='footercol location'>
          <h1 style={{ fontSize: `${fontSize*3.5}px` }}>Contact</h1>
          <p style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.5: 1.3)}px` }}><HashLink target="_blank" to="mailto:chelsie.pasley@gmail.com">Chelsie.Pasley@gmail.com</HashLink></p>
          <p style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.5: 1.3)}px` }}><HashLink target="_blank" to="tel:540-878-8988">540-878-8988</HashLink></p>
        </div>
        <div className='footercol social'>
          <h1 style={{ fontSize: `${fontSize*3.5}px` }}>Social Media</h1>
          <div>
            <HashLink to="https://www.facebook.com/people/Bonded-Births-Doula-Services/61550988598253/" target="_blank"><img alt="facebook" src={require("./assets/facebook.png")}></img></HashLink>
            <HashLink to="https://www.instagram.com/bonded.births/" target="_blank"><img alt="instagram" src={require("./assets/instagram.png")}></img></HashLink>
          </div>
        </div>
    </div>
    <p style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.5: 1.3)}px` }} className='locationp'>Servicing Culpeper, Fauquier, Orange, Madison, Charlottesville, Fredericksburg, and Northern Virginia.</p>
    <div  style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.5: 1.3)}px` }} className='createdby'>
        Website by <a href="http://onebytewonders.com" target='_blank'>OneByteWonders</a>
    </div>
    </div>
  )
}

const Home = () => {

  // const [animate1, setAnimate1] = useState("")
  // const [animate2, setAnimate2] = useState("")
  // const [animate3, setAnimate3] = useState("")
  // const [animate4, setAnimate4] = useState("")
  // const [animate5, setAnimate5] = useState("")
  // const [count, setCount] = useState(1)
  return (
  <>
      <div id="home" className="home">
        {/* <img className="preload" src={require("./assets/backgrounds/just1 blue.jpg")} onLoad={()=>setAnimate1("animate1")}></img>
        <img className="preload" src={require("./assets/backgrounds/just2.png")} onLoad={()=>setAnimate2("animate2")}></img>
        <img className="preload" src={require("./assets/backgrounds/just3.png")} onLoad={()=>setAnimate3("animate3")}></img>
        <img className="preload" src={require("./assets/backgrounds/just4.png")} onLoad={()=>setAnimate4("animate4")}></img>
        <img className="preload" src={require("./assets/backgrounds/just5.png")} onLoad={()=>setAnimate5("animate5")}></img> */}
        {/* <div className={"background1 "+animate1}></div> */}
        {/* {(animate1 != "") && <div className={"background2 "+animate2}></div>}
        {(animate2 != "") && <div className={"background3 "+animate3}></div>}
        {(animate3 != "") && <div className={"background4 "+animate4}></div>}
        {(animate4 != "") && <div className={"background5 "+animate5}></div>} */}
        {/* mobile */}
        {/* <img className="preload" src={require("./assets/backgrounds/mobile1.png")} onLoad={()=>setCount(1)}></img>
        <img className="preload" src={require("./assets/backgrounds/mobile2.png")} onLoad={()=>setCount(1)}></img>
        <img className="preload" src={require("./assets/backgrounds/mobile3.png")} onLoad={()=>setCount(1)}></img> */}
        {/* <img className="preload" src={require("./assets/backgrounds/mobile4.png")}></img> */}
        {/* <img className="preload" src={require("./assets/backgrounds/mobile5.png")} onLoad={()=>setCount(1)}></img> */}
        {/* {(count == 1) && <div className={"background6"}></div>} */}
        <h1 className='homeh1' style={{ fontSize: `${fontSize*13.75}px` }}>Embrace Your Birth.</h1>
        <h1 className='mobile1' style={{ fontSize: `${fontSize*8.5}px` }}>Embrace Your Birth.</h1>
      </div>
    <Testimonials></Testimonials>
      </>
  )
}


const Nav = () => {
  const location = useLocation();

  const [navSize, setNavSize] = useState("")
  useEffect(()=> {
      const handleScroll = () => {
         let p = window.scrollY
          setNavSize((p > 20) ? "wide" : (navSize === "wide" || navSize === "thin") ? "thin" : "")
      };
      window.addEventListener("scroll", handleScroll);
      return(() => {
         window.removeEventListener("scroll", handleScroll);
      })
  })

  return (
    <div className={" nav " + navSize}>
      <img className="icon" src={require("./assets/BondedBirths2.png")}></img>
      <p style={{ fontSize: `${fontSize*1}px` }}><HashLink to="/#home"  className="logo">Bonded Births llc</HashLink></p>
      <div>
      <HashLink style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.3: 1.1)}px` }} to="/#testimonials">TESTIMONIALS</HashLink>
      <HashLink style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.3: 1.1)}px` }} to="/aboutme">ABOUT ME</HashLink>
      <HashLink style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.3: 1.1)}px` }} to="/services">SERVICES</HashLink>
      <HashLink style={{ fontSize: `${fontSize*((window.innerHeight > window.innerWidth)?1.3: 1.1)}px` }} to="/contactme">CONTACT ME</HashLink>
       </div>
    </div>
  )
}

export default App;
